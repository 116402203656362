import React from 'react';
import { 
  Box,
  Container, 
  Flex, 
  Text, 
  SimpleGrid, 
  Heading, 
  HStack,
  Button, 
  Input, 
  useNumberInput, 
  UnorderedList, 
  ListItem,
} from '@chakra-ui/react';
import { EmblaCarousel } from './../components/EmblaCarousel';

const Main = ({ merchItems, addToCart, cart, getCartTotal, getTotalSum, removeFromCart }) => {

    const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 1,
      defaultValue: 1,
      min: 1,
      max: 10,
      precision: 0,
    }) 

    const inc = getIncrementButtonProps()
    const dec = getDecrementButtonProps()
    const input = getInputProps()

    return (
        <Box>
            <Container maxWidth="1200px" pr={{base: '27px', md: '0', lg: '52px', xl: '0'}} pl={{base: '20px', md: '0', lg: '52px', xl: '0'}}>
                <SimpleGrid columns={{base: 1, lg: 2}}>
                    <EmblaCarousel 
                    options={
                        { 
                        loop: true,
                        breakpoints: {
                            '(min-width: 48em)': { 
                            slidesToScroll: 'auto', 
                            containScroll: 'trimSnaps',
                            align: 0.5,
                            },
                            '(min-width: 60em)': { 
                            active: false,
                            },
                        }
                        }
                    }
                    />
                    <Box pl={{base: '7px', md: '52px', lg: '40px'}} pr={{md: '52px', lg: '0'}} >
                        <Box className='title' mb={{base: '35px', lg: '41px'}} mt={{base: '58px', md: '27px', lg: '0'}}>
                            <Heading as='h1' textAlign="left" fontWeight="400" fontSize={{base: '30px', md: '40px', lg: '48px'}} color="brand.400" mb={{base: '20px', lg: '17px'}} lineHeight="normal">Collection «Money»</Heading>
                            <Text color="brand.400" fontWeight="500" fontSize={{base: '24px', lg: '30px'}}>17 000 ₽</Text>
                            <Box borderBottom="0.5px solid" borderColor="brand.300" mt={{base: '35px', lg: '31px'}}></Box>
                        </Box>
                        <Box className='specifications' mb="45px">
                            <Flex>
                                <Box mr="36px">
                                    <Box mb="16px">
                                        <Text color="brand.300" fontSize="14px">Размер</Text>
                                    </Box>

                                    <Box bg="brand.300" padding="8px 13px" borderRadius="10px">
                                        <Text color="brand.100" fontSize="18px" fontWeight="500" textTransform="uppercase">One Size</Text>
                                    </Box>
                                </Box>

                                <Box>
                                    <Box mb="16px">
                                        <Text color="brand.300" fontSize="14">Количество</Text>
                                    </Box>

                                    <Box>
                                        <HStack maxW='320px' border="1px solid" borderColor="brand.300" borderRadius="10px">
                                            <Button className='dec' {...dec} borderRadius="10px" bg="transparent" border='0' color='brand.300' fontSize='18px'>-</Button>
                                            <Input {...input} height="39px" width="50px" borderColor="transparent" textAlign="center" disabled/>
                                            <Button className='inc' {...inc} borderRadius="10px" bg="transparent" border='0' color='brand.300' fontSize='18px'>+</Button>
                                        </HStack>
                                    </Box>
                                </Box>
                            </Flex>
                        </Box>

                        <Box className='content' mb="45px">
                            <Box>
                                <Box mb="16px">
                                    <Text color="brand.300" fontSize="14px">Содержание коллекции</Text>
                                </Box>

                                <Box>
                                    <UnorderedList color="brand.400" fontSize="16px" spacing={2}>
                                    <ListItem>Худи с настоящей редкой 2$ купюрой</ListItem>
                                    <ListItem>Пыльник</ListItem>
                                    <ListItem>Вкладыш</ListItem>
                                    <ListItem>Подарочные стикеры</ListItem>
                                    <ListItem>Кейс</ListItem>
                                    </UnorderedList>
                                </Box>
                            </Box>
                        </Box>
                        <Box className='structure' mb="60px">
                            <Box>
                                <Box mb="16px">
                                    <Text color="brand.300" fontSize="14px">Состав ткани</Text>
                                </Box>
                                <Box>

                                    <UnorderedList color="brand.400" fontSize="16px" >
                                        <ListItem>Хлопок трехнитка (ро = 470г)</ListItem>
                                    </UnorderedList>
                                </Box>
                            </Box>
                        </Box>

                        <Box>
                            <Button onClick={() => addToCart(merchItems[0], input.value)} className='btnBuy' colorScheme='gray' variant='solid' width="100%" size="lg" fontSize='18px' fontWeight='500'>
                            Оформить заказ
                            </Button>
                        </Box>
                    </Box>
                </SimpleGrid>
            </Container>
        </Box>
    );    
}

export default Main;