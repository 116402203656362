import React from "react";

const Steps = () => {
    return (
        <div className="progress__container">
            <div className='progress__stage'>
                <div className='progress__stage-step'>
                    <p className='progress__stage-step-number'>1</p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                        <path d="M5 10C5.68302 10 6.32599 9.86927 6.92891 9.60782C7.53186 9.3464 8.06372 8.98529 8.52449 8.52449C8.98529 8.06372 9.3464 7.53186 9.60782 6.92891C9.86927 6.32599 10 5.68302 10 5C10 4.31699 9.86927 3.67401 9.60782 3.07107C9.3464 2.46813 8.98529 1.93627 8.52449 1.47549C8.06372 1.0147 7.53104 0.653594 6.92645 0.392156C6.32189 0.130719 5.6781 0 4.99509 0C4.31209 0 3.66911 0.130719 3.06617 0.392156C2.46323 0.653594 1.93219 1.0147 1.47304 1.47549C1.01389 1.93627 0.653594 2.46813 0.392156 3.07107C0.130719 3.67401 0 4.31699 0 5C0 5.68302 0.130719 6.32599 0.392156 6.92891C0.653594 7.53186 1.0147 8.06372 1.47549 8.52449C1.93627 8.98529 2.46813 9.3464 3.07107 9.60782C3.67401 9.86927 4.31699 10 5 10ZM5 9.16665C4.42156 9.16665 3.88071 9.05881 3.37745 8.84313C2.87418 8.62746 2.43219 8.32926 2.05147 7.94854C1.67075 7.56782 1.37336 7.12583 1.15931 6.62256C0.94526 6.1193 0.838233 5.57844 0.838233 5C0.838233 4.42156 0.94526 3.88071 1.15931 3.37745C1.37336 2.87418 1.66993 2.43137 2.04902 2.04902C2.4281 1.66666 2.86928 1.36846 3.37254 1.15441C3.87581 0.940359 4.41666 0.833334 4.99509 0.833334C5.57353 0.833334 6.11438 0.940359 6.61764 1.15441C7.12091 1.36846 7.56372 1.66666 7.94608 2.04902C8.32844 2.43137 8.62746 2.87418 8.84313 3.37745C9.05881 3.88071 9.16665 4.42156 9.16665 5C9.16665 5.57844 9.05963 6.1193 8.84559 6.62256C8.63152 7.12583 8.33413 7.56782 7.95341 7.94854C7.57269 8.32926 7.12988 8.62746 6.62497 8.84313C6.1201 9.05881 5.57844 9.16665 5 9.16665Z" fill="#949182"/>
                        <path d="M2.46875 5.19296L4.16519 6.88953L7.53129 3.52344" stroke="#949182" stroke-width="0.834832" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <p className='progress__stage-step-name'>
                        Выберите товар
                    </p>
                </div>

                <hr className='progress__stage-line'></hr>

                <div className={'progress__stage-step completed'}>
                    <p className='progress__stage-step-number'>2</p>
                    <p className='progress__stage-step-name'>Заполните данные</p>
                </div>
                <hr className='progress__stage-line'></hr>

                <div className={'progress__stage-step '}>
                    <p className='progress__stage-step-number'>3</p>
                    <p className='progress__stage-step-name'>Укажите адрес доставки</p>
                </div>

                <hr className='progress__stage-line'></hr>

                <div className={'progress__stage-step '}>
                    <p className='progress__stage-step-number'>4</p>
                    <p className='progress__stage-step-name'>Оплатите</p>
                </div>
            </div>
        </div>
    )

}

export default Steps;