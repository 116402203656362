import React from 'react';
import { 
  Box,
  Container, 
  Flex, 
  Text, 
  Heading, 
  HStack,
  Button, 
  Input, 
  useNumberInput, 
  Image,
  Stack,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react';
import { PhoneIcon, CheckIcon, EmailIcon, createIcon, Icon } from '@chakra-ui/icons'
import { useNavigate } from 'react-router-dom'


const Delivery = ({ checkoutData, setCheckoutData }) => {

    const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 1,
      defaultValue: 1,
      min: 1,
      max: 10,
      precision: 0,
    })

    const inc = getIncrementButtonProps()
    const dec = getDecrementButtonProps()
    const input = getInputProps()

    let navigate = useNavigate()

    var popStateEvent = new PopStateEvent('popstate');
    dispatchEvent(popStateEvent);

    const removeSpaces = (value) => {
        return value.replace(/\s/g, ''); // Удаляем все пробелы
    };

    if(!checkoutData.fullName || !checkoutData.phone || !checkoutData.email) {
        navigate('/', {replace: 'true'})
    }
    return (
        <>
            <Box textAlign='center' display={{base: 'block', lg: 'none'}} mb='20px'>
                <Text textTransform='uppercase' fontSize='14px' color='brand.400' fontWeight='600'>Укажите адрес доставки</Text>
            </Box>

            <Box bg='white' w='100%' py='15px' px='10px' borderRadius='10px' maxWidth='450px' margin='0 auto'>
                <div id="saferoute-cart-widget"></div>
            </Box>
        </>
    );    
}

export default Delivery;