import React from 'react';
import { 
  Box,
  Container, 
  Flex, 
  Text, 
  Heading, 
  HStack,
  Button, 
  Input, 
  useNumberInput, 
  Image,
  Link as ChakraLink, 
  LinkProps,
  SimpleGrid,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom';
import photo1 from './../assets/images/photo1.jpg';
import { useEffect } from 'react';
import vk from './../assets/images/vk.svg';
import tg from './../assets/images/tg.svg'

const About = () => {

    return (
        <Box>
            <Container maxWidth="1200px" px={{base: '27px', md: '52px', xl: '0'}}>
                <Heading as='h1' fontWeight="400" fontSize={{base: '30px', md: '40px', lg: '48px'}} color="brand.400" mb={{base: '38px', md: '57px'}} mt='30px' lineHeight="normal">О нас</Heading>
                <Grid
                    templateRows={{base: 'repeat(1, 1fr)'}}
                    templateColumns='repeat(5, 1fr)'
                    gap={4}
                >
                    <GridItem colSpan={{base: 5, lg: 5}}>
                    </GridItem>

                    <GridItem colSpan={{base: 5, lg: 5}}>
                        <Box mb='1rem'>MOSSAIC – премиальный бренд одежды, проявляющийся в смелых и нестандартных решениях.</Box> 
                        <Box mb='1rem'>Коллекции MOSSAIC создаются по принципу недостающего пазла, закрывая потребность обладателя в поиске эксклюзивных решений для своего гардероба.</Box>
                        <Box mb='1rem'>Стань частью MOSSAIC — собери свой недостающий пазл.</Box>
                    </GridItem>
                </Grid>
            </Container>
        </Box>
    );    
}

export default About;