import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import AutoScrollPage from './components/AutoScrollPage';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { defineStyle, defineStyleConfig } from '@chakra-ui/react'
import {BrowserRouter} from 'react-router-dom';
import "@fontsource/golos-text/400.css";
import "@fontsource/golos-text/500.css";
import "@fontsource/golos-text/600.css";

const root = ReactDOM.createRoot(document.getElementById('root'));

const outline = defineStyle({
  border: '1px solid #949182',
  borderRadius: '10px',
  fontWeight: 400,
  fontSize: '14px',
  color: '#4E4734',
  _hover: {
    bg: '#4E4734',
    color: 'white',
    border: '1px solid #4E4734',
  },
  _focus: {
    bg: '#4E4734',
    color: 'white',
    border: '1px solid #4E4734',
  },
  _active: {
    bg: '#4E4734',
    color: 'white',
    border: '1px solid #4E4734',
  },
})
const solid = defineStyle({
  bg: '#4E4734',
  border: '1px solid #4E4734',
  borderRadius: '10px',
  fontWeight: 400,
  fontSize: '14px',
  color: 'white',
  _hover: {
    bg: '#363122',
    color: 'white',
    border: '1px solid #363122',
  },
  _focus: {
    bg: '#363122',
    color: 'white',
    border: '1px solid #363122',
  },
  _active: {
    bg: '#363122',
    color: 'white',
    border: '1px solid #363122',
  },
})

export const buttonTheme = defineStyleConfig({
  variants: { outline, solid },
})

const theme = extendTheme({
  styles: {
    global: {
      'html': {
        fontSize: '16px',
        fontWeight: '400',
      },
      '.embla': {
        overflow: 'hidden',
      },
      '.embla__container': {
        display: 'flex',
        borderRadius: '10px',
      },
      '.embla__slide': {
        flex: '0 0 100%',
        minWidth: '0',
        paddingLeft: '7px',
      },
      '.embla__slide img': {
        borderRadius: '10px',
        height: '420px',
        width: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
      },
      '.embla__slide video': {
        borderRadius: '10px',
        height: '420px!important',
        width: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
      },
      '@media(min-width: 30em)': {
        '.embla__slide img': {
          height: '500px',
        },
        '.embla__slide video': {
          height: '500px!important',
        },
      },
      '@media(min-width: 48em)': {
        '.embla__slide img': {
          height: '300px',
        },
        '.embla__slide video': {
          height: '300px!important',
        },
        '.embla__slide': {
          flex: '0 0 33%',
        },
      },
      '@media(min-width: 60em)': {
        '.embla__container': {
          flexWrap: 'wrap',
        },
        '.embla__slide img': {
          height: '400px',
        },
        '.embla__slide video': {
          width: '100%',
          height: 'auto!important',
        },
        '.embla__slide': {
          flex: '0 0 50%',
          paddingLeft: '0',
          paddingBottom: '9.5px',
        },
        '.embla__slide:nth-child(even)': {
          paddingLeft: '9.5px',
        },
        '.embla__slide:last-child': {
          flex: '0 0 100%'
        }
      },

      '.player-wrapper': {
        position: 'relative',
        paddingTop: '66.625%',
      },
      '.react-player': {
        position: 'absolute',
        top: '0',
        left: '0',
      }
      
    },
  },
  colors: {
    brand: {
      100: "#F1EBDE",
      200: "#EBE4D5",
      300: "#949182",
      400: "#4E4734",
    },
  },
  fonts: {
    heading: `Golos Text`,
    body: `Golos Text`,
  },
  components: { Button: buttonTheme },
})

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <AutoScrollPage />
        <App />
      </ChakraProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
