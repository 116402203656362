import React from 'react';
import { 
  Box,
  Container, 
  Flex, 
  Text, 
  Heading, 
  HStack,
  Button, 
  Input, 
  useNumberInput, 
  Image,
  Link as ChakraLink, 
  LinkProps,
} from '@chakra-ui/react';
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom';
import photo1 from './../assets/images/photo1.jpg';
import { useEffect } from 'react';

const Cart = ({ merchItems, addToCart, cart, setCart, getCartTotal, getTotalSum, removeFromCart }) => {
    let navigate = useNavigate();

    useEffect(() => {
        if(cart.length == 0) {
            navigate('/', {replace: false})
        }
    }, []);

    const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 1,
      defaultValue: cart.count,
      min: 1,
      max: 10,
      precision: 0,
      onChange: (valueAsString, valueAsNumber) => addToCart(merchItems[0], valueAsNumber), 
    })

    const inc = getIncrementButtonProps()
    const dec = getDecrementButtonProps()
    const input = getInputProps()

    return (
        <Box>
            <Container maxWidth="1200px" px={{base: '27px', md: '52px', xl: '0'}}>
                <Heading as='h1' fontWeight="400" fontSize={{base: '30px', md: '40px', lg: '48px'}} color="brand.400" mb={{base: '38px', md: '57px'}} mt='30px' lineHeight="normal">Ваши заказы</Heading>
               
                <Flex pb='20px' mb='15px' borderBottom='0.5px solid' borderColor='brand.300' color='brand.300' fontSize='16px' display={{base: 'none', lg: 'flex'}}>
                    <Box flex={{base: '0 0 44.7%'}}>
                        <Text>Продукт</Text>
                    </Box>
                    <Box flex={{base: '0 0 23.9%'}}>
                        <Text>Количество</Text>
                    </Box>
                    <Box flex={{base: '0 0 30.9%'}}>
                        <Text>Итог</Text>
                    </Box>
                </Flex>

                <Flex borderBottom='0.5px solid' borderColor='brand.300' pb='15px' mb={{base: '50px'}}>
                    <Box flex={{base: '0 0 34%', md: '0 0 14%'}}>
                        <Image src={photo1} height={{base: '120px'}} width='100%' objectFit='cover' borderRadius='10px' />
                    </Box>

                    <Flex flex={{base: '0 0 61%', md: '0 0 81%'}} pl={{base: '15px'}} flexDirection={{base: 'column', md: 'row'}} alignItems={{md: 'center'}}>
                        <Flex flex={{md: '0 0 37%'}} order={{base: 1}} flexDirection='column' mb={{base: '9px'}}>
                            <Text fontSize='18px' color='brand.400'>Collection «Money»</Text>
                            <Text fontSize='14px' color='brand.300' textTransform='uppercase'>One Size</Text>
                        </Flex>

                        <Flex flex={{md: '0 0 30%'}} order={{base: 2}}>
                            <HStack maxW='320px' border="1px solid" borderColor="brand.300" borderRadius="10px" h='33px' mb={{base: '5px'}} className='count'>
                                <Button className='dec' {...dec} borderRadius="10px" bg="transparent" h='32px' border='0' color='brand.300' fontSize='18px'>-</Button>
                                <Input {...input} height="33px" width="19px" borderColor="transparent" textAlign="center" paddingInlineStart={0} paddingInlineEnd={0} disabled />
                                <Button className='inc' {...inc} borderRadius="10px" bg="transparent" h='32px' border='0' color='brand.300' fontSize='18px'>+</Button>
                            </HStack>
                        </Flex>

                        <Box flex={{md: '0 0 33%'}} order={{base: 2}}>
                            <Text color='brand.400' fontSize='20px' fontWeight='500'>{cart.sum} ₽</Text>
                        </Box>
                    </Flex>

                    <Flex flex={{base: '0 0 5%'}} alignItems='center' justifyContent='flex-end'>
                        <Button variant='link' maxWidth='27px' width='100%' minWidth='auto' onClick={removeFromCart}>
                            <svg xmlns="http://www.w3.org/2000/svg" width='100%' height="auto" viewBox="0 0 27 28" fill="none">
                                <path d="M1 1.50098L26 26.4996" stroke="#949182" stroke-width="1.5"/>
                                <path d="M1 26.4985L26 1.49995" stroke="#949182" stroke-width="1.5"/>
                            </svg>
                        </Button>   
                    </Flex>
                </Flex>

                <Flex className='total' justifyContent={{md: 'flex-end'}}>
                    <Box maxWidth={{base: 'auto', md: '401px'}} width='100%'>
                        <Flex alignItems='center' mb={{base: '24px'}} justifyContent={{base: 'center', md: 'flex-start'}}>
                            <Box fontSize={{base: '20px'}} color='brand.400' mr='15px'>Сумма к оплате</Box>
                            <Box fontSize={{base: '28px'}} color='brand.400' fontWeight='500'>{cart.sum} ₽</Box>
                        </Flex>
                        
                        <Box>
                            <Button as={ReactRouterLink} to='/order' className='btnBuy' colorScheme='gray' variant='solid' width={{base: '100%'}} size="lg">
                                Перейти к оформлению
                            </Button>
                        </Box>
                    </Box>
                </Flex>
            </Container>
        </Box>
    );    
}

export default Cart;