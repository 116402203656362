import React from 'react';
import Header from './../components/Header';
import Footer from './../components/Footer';
import {
    Outlet,
} from 'react-router-dom';
import { 
    Box,
} from '@chakra-ui/react';

const MainLayout = ({ cart }) => {  
    return (
        <Box pt={{base: '15px', md: '20px', xl: '20px'}}>
            <Header cart={cart}/>
            <Outlet/>
            <Footer/>
        </Box>
    );
}

export default MainLayout;