import './App.css';
import React, { useEffect, useState } from 'react';
import { 
  Box,
} from '@chakra-ui/react';
import {
  Routes,
  Route,
  useNavigate,
} from 'react-router-dom';
import MainLayout from './layouts/MainLayout';
import OrderLayout from './layouts/OrderLayout';
import Main from './pages/Main';
import Cart from './pages/Cart';
import Details from './pages/Order/Details';
import Delivery from './pages/Order/Delivery';
import Contacts from './pages/Contacts';
import Refund from './pages/Refund';
import PrivacyPolicy from './pages/PrivacyPolicy';
import About from './pages/About';

function App() {
  let navigate = useNavigate();
  const [cart, setCart] = useState((JSON.parse(localStorage.getItem('cart')) ? JSON.parse(localStorage.getItem('cart')) : []));
  const [checkoutData, setCheckoutData] = useState((JSON.parse(localStorage.getItem('checkoutData')) ? JSON.parse(localStorage.getItem('checkoutData')) : []));

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  useEffect(() => {
    localStorage.setItem("checkoutData", JSON.stringify(checkoutData));
  }, [checkoutData]);

  console.log(checkoutData)
  const merchObject = [
    {
      id: 1,
      title: "Collection «Money»",
      description: "",
      img: [""],
      price: 17000,
      availability: "1",
      sizes: ["ONE SIZE"],
      vendorCode: "collectionmoney",
      ostatok: {
        OS: 100
      },
      structure: ""
    }
  ];

  const [merchItems, setMerchItems] = useState(merchObject);

  const getCartTotal = () => {
    return cart.reduce(
      (sum, { count }) => sum + count,
      0
    );
  };

  const getTotalSum = () => {
    return cart.reduce(
      (sum, { price, count }) => sum + price * count,
      0
    );
  };

  const removeFromCart = () => {
    setCart([]);
    navigate('/', {replace: false})
  }

  const addToCart = (product, count) => {
    let productObject = {
      ...product,
      count: parseInt(count, 10),
      sum: product.price*count,
      size: 'ONE SIZE',
    };

    setCart(productObject);
    
    navigate('/cart', {replace: false})
  };

  return (
    <Box className="App" bg="brand.100">
      <Routes>
        <Route exact path="" element={<MainLayout cart={cart} />}>
          <Route exact path="" index element={<Main merchItems={merchItems} addToCart={addToCart} cart={cart} getCartTotal={getCartTotal} getTotalSum={getTotalSum} removeFromCart={removeFromCart}/>}/>
          <Route exact path="cart" index element={<Cart merchItems={merchItems} addToCart={addToCart} cart={cart} setCart={setCart} getCartTotal={getCartTotal} getTotalSum={getTotalSum} removeFromCart={removeFromCart}/>}/>
          <Route exact path="contacts" index element={<Contacts/>} />
          <Route exact path="refund" index element={<Refund />} />
          <Route exact path="privacypolicy" index element={<PrivacyPolicy />} />
          <Route exact path="about" index element={<About />} />
        </Route>
        <Route exact path="order" element={<OrderLayout cart={cart} checkoutData={checkoutData} setCheckoutData={setCheckoutData}  />}>
            <Route exact path="" index element={<Details checkoutData={checkoutData} setCheckoutData={setCheckoutData}/>}/>
            <Route exact path="details" index element={<Details checkoutData={checkoutData} setCheckoutData={setCheckoutData}/>}/>
            <Route exact path="delivery" index element={<Delivery checkoutData={checkoutData} setCheckoutData={setCheckoutData} />}/>
        </Route>
      </Routes>
    </Box>
  );
}

export default App;
