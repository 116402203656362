import React from 'react';
import { 
  Box,
  Container, 
  Flex, 
  Text, 
  Heading, 
  HStack,
  Button, 
  Input, 
  useNumberInput, 
  Image,
  Link as ChakraLink, 
  LinkProps,
  SimpleGrid,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom';
import photo1 from './../assets/images/photo1.jpg';
import { useEffect } from 'react';
import vk from './../assets/images/vk.svg';
import tg from './../assets/images/tg.svg'

const Contacts = () => {
    return (
        <Box>
            <Container maxWidth="1200px" px={{base: '27px', md: '52px', xl: '0'}}>
                <Heading as='h1' fontWeight="400" fontSize={{base: '30px', md: '40px', lg: '48px'}} color="brand.400" mb={{base: '38px', md: '57px'}} mt='30px' lineHeight="normal">Контакты</Heading>
                <Grid
                    templateRows={{base: 'repeat(2, 1fr)', lg: 'repeat(1, 1fr)'}}
                    templateColumns='repeat(5, 1fr)'
                    gap={4}
                >
                    <GridItem colSpan={{base: 5, lg: 4}}>
                        <Box mb={{base: '13px', md: '16px'}}>
                            <Text fontSize={{base: '14px', lg: '20px'}} fontWeight='500' textTransform='uppercase' color='brand.300'>Почта</Text>
                        </Box>
                        <Box>
                            <Text fontSize={{base: '24px', md: '32px', lg: '64px'}} fontWeight='400' color='brand.400'>mossaic.brand@gmail.com</Text>
                        </Box>
                    </GridItem>
                    <GridItem colSpan={{base: 5, lg: 1}}>
                        <Box mb={{base: '20px', md: '23px'}}>
                            <Text fontSize={{base: '14px', lg: '20px'}} fontWeight='500' textTransform='uppercase' color='brand.300'>Соц.сети</Text>
                        </Box>
                        <Box>
                            <Flex justifyContent='flex-start'>
                                <Box mr='15px'>
                                    <ChakraLink to='https://vk.com/public222034546' as={ReactRouterLink}>
                                        <Image src={vk} w='64px' h='64px'/>
                                    </ChakraLink>
                                </Box>
                                <Box>
                                    <ChakraLink to='https://t.me/mossaicbrand' as={ReactRouterLink}>
                                        <Image src={tg} w='64px' h='64px'/>
                                    </ChakraLink>
                                </Box>
                            </Flex>
                        </Box>
                    </GridItem>
                </Grid>
            </Container>
        </Box>
    );    
}

export default Contacts;