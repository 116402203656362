import React from 'react';
import { 
    Box,
    Flex, 
    Text, 
    Image, 
    useDisclosure, 
    Collapse,
    Container,
    Link as ChakraLink, 
    LinkProps,
  } from '@chakra-ui/react';

import { Link as ReactRouterLink } from 'react-router-dom';
import logo from './../assets/images/logo.svg';
import logoMobile from './../assets/images/logoMobile.svg';
import menuIcon from './../assets/images/bag.svg';

function Header ({ cart }) {
    const { isOpen, onToggle } = useDisclosure();
    
    return (
        <Container maxWidth="1200px" px={{base: '27px', md: '52px', xl: '0'}}>
            <Box as='header' bg="white" borderRadius="10px" className='nav' mb={{base: '15px', md: '20px', xl: '30px'}}>
                <Flex 
                    alignItems="center"
                    py={{base: '6.49px', md: '12.74px'}}
                    px="20px"
                >
                    <Flex flex="0 0 33.3333%">
                        <Box 
                            w="25px"
                            h="24px"
                            pt="7px"
                            cursor="pointer"
                            onClick={onToggle}
                            className={'burger'+(isOpen ? ' active' : '')}
                            sx={{
                                ':before' : {
                                    position: 'relative',
                                    display: 'block',
                                    content: '""',
                                    height: '1px',
                                    width: '100%',
                                    top: '0',
                                    background: 'brand.400'
                                },
                                ':after' : {
                                    position: 'relative',
                                    display: 'block',
                                    content: '""',
                                    height: '1px',
                                    width: '100%',
                                    marginTop: '8px',
                                    background: 'brand.400'
                                },
                            }}
                        >
                        </Box>
                    </Flex>

                    <Flex flex="0 0 33.3333%" justifyContent="center">
                        <ChakraLink as={ReactRouterLink} to="/">
                            <Image src={logo} display={{base: 'none', md: 'block'}}/>
                            <Image src={logoMobile} display={{base: 'block', md: 'none'}}/>
                        </ChakraLink> 
                    </Flex>

                    <Flex flex="0 0 33.3333%" justifyContent="flex-end">
                        <ChakraLink as={ReactRouterLink} to={cart.length != 0 ? '/cart' : ''}>
                            <Flex alignItems="center">
                                <Box pr="5px"><img src={menuIcon} /></Box>
                                <Text color="brand.400" textTransform="uppercase" pr="2px" display={{base: 'none', md: 'block'}}>Корзина</Text>
                                <Text color="brand.300">({cart.length != 0 ? cart.count : '0'})</Text>
                            </Flex>
                        </ChakraLink>
                    </Flex>
                </Flex>

                <Collapse in={isOpen} animateOpacity>
                    <Box className='nav__menu' px="20px" borderTop='0.5px solid' borderColor='brand.300' pb={{base: '20px', lg: '30px'}}>

                        <Flex flexDirection={{base: 'column', lg: 'row'}} justifyContent={{lg: 'space-between'}} alignItems={{lg: 'center'}} mt='30px'>
                            <Flex color='brand.400' flexDirection={{base: 'column', lg: 'row'}}>
                                <Box mb={{base: '20px', lg: 0}} fontWeight='600' mr={{lg: '65px'}}>
                                    <ChakraLink as={ReactRouterLink} to="/" onClick={onToggle}>Collection «Money»</ChakraLink>
                                </Box>
                                <Box mb={{base: '20px', lg: 0}} mr={{lg: '65px'}}>
                                    <ChakraLink as={ReactRouterLink} to="/about" onClick={onToggle}>О нас</ChakraLink>
                                </Box>
                                <Box mb={{base: '20px', lg: 0}} mr={{lg: '65px'}} >
                                    <ChakraLink as={ReactRouterLink} to="/contacts" onClick={onToggle}>Контакты</ChakraLink>
                                </Box>
                                <Box mb={{base: '20px', lg: 0}} mr={{lg: '65px'}}>
                                    <ChakraLink as={ReactRouterLink} to="/refund" onClick={onToggle}>Возврат</ChakraLink>
                                </Box>
                                <Box mb={{base: '30px', lg: 0}}>
                                    <ChakraLink as={ReactRouterLink} to="/privacypolicy" onClick={onToggle}>Пользовательское соглашение</ChakraLink>
                                </Box>
                            </Flex>

                            <Box>
                                <Flex>
                                    <Box mr='15px'>
                                        <ChakraLink as={ReactRouterLink} to='https://vk.com/public222034546'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none">
                                                <circle cx="20" cy="20.2312" r="20" fill="#F1EBDE"/>
                                                <path d="M29.5823 26.4209H27.3013C26.4383 26.4209 26.1783 25.7219 24.6313 24.1739C23.2793 22.8709 22.7083 22.7089 22.3663 22.7089C21.8933 22.7089 21.7643 22.8389 21.7643 23.4899V25.5419C21.7643 26.0969 21.5843 26.4219 20.1343 26.4219C18.7276 26.3274 17.3635 25.9 16.1543 25.175C14.9451 24.45 13.9255 23.4482 13.1793 22.2519C11.4077 20.0469 10.1751 17.459 9.57935 14.6939C9.57935 14.3519 9.70935 14.0419 10.3613 14.0419H12.6413C13.2273 14.0419 13.4383 14.3029 13.6683 14.9059C14.7753 18.1639 16.6643 20.9979 17.4313 20.9979C17.7253 20.9979 17.8533 20.8679 17.8533 20.1349V16.7789C17.7563 15.2479 16.9433 15.1189 16.9433 14.5649C16.9536 14.4187 17.0205 14.2823 17.1297 14.1847C17.239 14.087 17.382 14.0358 17.5283 14.0419H21.1123C21.6023 14.0419 21.7643 14.2859 21.7643 14.8719V19.4019C21.7643 19.8909 21.9743 20.0529 22.1223 20.0529C22.4163 20.0529 22.6423 19.8909 23.1813 19.3529C24.3367 17.9439 25.2806 16.3741 25.9833 14.6929C26.0553 14.491 26.1913 14.3183 26.3705 14.2009C26.5498 14.0835 26.7626 14.028 26.9763 14.0429H29.2573C29.9413 14.0429 30.0863 14.3849 29.9413 14.8729C29.1117 16.7313 28.0852 18.4954 26.8793 20.1349C26.6333 20.5099 26.5353 20.7049 26.8793 21.1449C27.1053 21.4869 27.9053 22.1549 28.4423 22.7899C29.2243 23.5699 29.8733 24.4719 30.3643 25.4599C30.5603 26.0959 30.2333 26.4209 29.5823 26.4209Z" fill="#4E4734"/>
                                            </svg>
                                        </ChakraLink>
                                    </Box>

                                    <Box>
                                        <ChakraLink as={ReactRouterLink} to='https://t.me/mossaicbrand'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none">
                                                <circle cx="20" cy="20.2312" r="20" fill="#F1EBDE"/>
                                                <path d="M27.2562 12.2947L10.1598 18.9215C9.47186 19.2301 9.23917 19.8481 9.99356 20.1835L14.3795 21.5846L24.9842 14.9968C25.5632 14.5832 26.156 14.6935 25.6459 15.1485L16.5379 23.4377L16.2518 26.9457C16.5168 27.4874 17.002 27.4899 17.3115 27.2207L19.8314 24.824L24.147 28.0724C25.1494 28.6689 25.6948 28.284 25.9105 27.1907L28.7412 13.7178C29.0351 12.3721 28.5339 11.7791 27.2562 12.2947Z" fill="#4E4734"/>
                                            </svg>
                                        </ChakraLink>
                                    </Box>
                                </Flex>
                            </Box>

                        </Flex>
                    

                        <Box pt='20px' mt={{base: '25px', lg: '45px'}} borderTop='1px solid' borderColor='brand.300'>
                            <Text color='brand.300' fontSize='14px'>ИП Орлов Р. А., ИНН: 525904102428</Text>
                        </Box>
                    
                    </Box>
                </Collapse>
            </Box>
        </Container>
    );
}

export default Header;