import React from 'react';
import { 
  Box,
  Container, 
  Flex, 
  Text, 
  Heading, 
  HStack,
  Button, 
  Input, 
  useNumberInput, 
  Image,
  Link as ChakraLink, 
  LinkProps,
  SimpleGrid,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom';
import photo1 from './../assets/images/photo1.jpg';
import { useEffect } from 'react';
import vk from './../assets/images/vk.svg';
import tg from './../assets/images/tg.svg'

const PrivacyPolicy = () => {

    return (
        <Box>
            <Container maxWidth="1200px" px={{base: '27px', md: '52px', xl: '0'}}>
                <Heading as='h1' fontWeight="400" fontSize={{base: '30px', md: '40px', lg: '48px'}} color="brand.400" mb={{base: '38px', md: '57px'}} mt='30px' lineHeight="normal">Пользовательское соглашение</Heading>
                <Grid
                    templateRows={{base: 'repeat(1, 1fr)'}}
                    templateColumns='repeat(5, 1fr)'
                    gap={4}
                >
                    <GridItem colSpan={{base: 5, lg: 5}}>
                    </GridItem>

                    <GridItem colSpan={{base: 5, lg: 5}}>
                        <pre style={{whiteSpace: 'pre-wrap'}}>

                        {`Настоящий документ «Пользовательское соглашение» (далее — Соглашение) представляет собой предложение ИП Орлов Р.А., размещенное на сайте https://mossaic.online/ (далее — «Сайт»), заключить договор на изложенных ниже условиях Соглашения. 

1. Общие положения

1.1. Вы настоящим подтверждаете, что с момента регистрации на Сайте и в течение времени пользования Сайтом, а также персонализированными сервисами Сайта, вы являетесь Пользователем Сайта вплоть до вашего личного обращения в администрацию Сайта с требованием об отказе от любых взаимоотношений с Сайтом.

1.2. Использование вами Сайта любым способом и в любой форме в пределах его объявленных функциональных возможностей, включая:

а) просмотр размещенных на Сайте материалов;

б) регистрация и/или авторизация на Сайте;

в) размещение или отображение на Сайте любых материалов, включая, но не ограничиваясь такими как: тексты, гипертекстовые ссылки, изображения, аудио и видео- файлы, сведения и/или иная информация;

создает договор на условиях настоящего Соглашения в соответствии с положениями ст.437 и 438 Гражданского кодекса Российской Федерации.

1.3. Воспользовавшись любой из указанных выше возможностей по использованию Сайта вы подтверждаете, что:

а) ознакомились с условиями настоящего Соглашения в полном объеме до начала использования Сайта;

б) принимаете все условия настоящего Соглашения в полном объеме без каких-либо изъятий и ограничений с вашей стороны и обязуетесь их соблюдать или прекратить использование Сайта. Если вы не согласны с условиями настоящего Соглашения или не имеете права на заключение договора на их основе, вам следует незамедлительно прекратить любое использование Сайта;

в) Соглашение (в том числе любая из его частей) может быть изменено Сайтом без какого-либо специального уведомления. Новая редакция Соглашения вступает в силу с момента ее размещения на Сайте либо доведения до сведения Пользователя в иной удобной форме, если иное не предусмотрено новой редакцией Соглашения. 

2. Общие условия пользования Сайтом

2.1. Сайт осуществляет продажу товара посредством веб-ресурса https://mossaic.online/ и сопутствующих сервисных служб Сайта.

2.2. Сайт осуществляет доставку товара способами, определенными в Части 5, пункт 5 настоящего Соглашения.

2.3. Сайт предоставляет доступ к персонализированным сервисам Сайта для получения наиболее полной информации по интересующему вас товару, создания рейтингов и мнений, участия в конкурсных программах и иных акциях, проводимых Сайтом.

2.4. Настоящим вы даете своё добровольное согласие на информирование вас обо всех действиях Сайта, связанных с продажей товара и/или оказанием услуг, в том числе о статусе выполнения заказа, а также об иных событиях любого характера, относящихся к сервисам Сайта.

2.5. Согласие на получение рассылки:

Подписываясь на SMS- и email-рассылку https://mossaic.online/ или третьих лиц, уполномоченных на рассылку от имени https://mossaic.online/ , в сети Интернет https://mossaic.online/ , я соглашаюсь получать информационную рассылку на телефон и электронный адрес, указанный мной при подписке через сервис подписки информационной рассылки на сайте https://mossaic.online/. Я проинформирован о том, что в случае, если у меня возникнет желание отказаться от информационной рассылки https://mossaic.online/, мне будет необходимо самостоятельно перейти по ссылке «Отписаться от рассылки», указанной в тексте отправляемых сайтом сообщений с электронного адреса Сайта https://mossaic.online/  или же сообщить о несогласии получения информационных рассылок по адресам, указанным в разделе «Контакты» данного Соглашения.

Я также даю разрешение Сайту или третьим лицам, уполномоченным на рассылку от имени Сайта, собирать, хранить и обрабатывать все переданные мною в адрес Сайта персональные данные (в том числе фамилию, имя, отчество и адрес электронной почты) с целью информирования о новостях и других событиях Сайта.

2.6. Вы соглашаетесь, что Сайт не несет никакой ответственности за задержки, сбои, неверную или несвоевременную доставку, удаление или несохранность каких-либо уведомлений. При этом Сайт оставляет за собой право повторного направления любого уведомления, в случае его неполучения Вами.

2.7. Все вопросы по информационной поддержке Вы можете задать по адресу fred.bred.ea@yandex.ru

2.8. Вы признаёте, что сопровождающее товара описание на Сайте не претендует на исчерпывающую информативность и может содержать неточности. Вы вправе направить все замечания по не точному описанию товара Сайту по адресу fred.bred.ea@yandex.ru

2.9. Вы признаёте, что Сайт прилагает достаточные усилия, чтобы внешний вид, упаковка и характеристики товаров соответствовали описаниям, приведенным в каталоге Сайта. Одновременно Сайт доводит до вашего сведения, что фактический внешний вид, упаковка и характеристики товара могут отличаться от этих описаний в случае ассортиментного товара, а также в случае внесения изменений в товар непосредственно производителем.

2.10. Вы проинформированы, что цена и наличие товара на Сайте изменяется круглосуточно без предварительного уведомления об этом и указываются в индивидуальном статусе и подробной карточке товара, отображаемым в каталоге на Сайте. 

3. Обязательства Пользователя при использовании Сайта

3.1. Вы соглашаетесь не использовать сервисы Сайта с целью:

3.1.1. загрузки контента, который является незаконным, нарушает любые права третьих лиц; пропагандирует насилие, жестокость, ненависть и/или дискриминацию по расовому, национальному, половому, религиозному, социальному признакам; содержит недостоверные сведения и/или оскорбления в адрес конкретных лиц, организаций, органов власти;

3.1.2. побуждения к совершению противоправных действий, а также содействия лицам, действия которых направлены на нарушение ограничений и запретов, действующих на территории РФ;

3.1.3. нарушения прав несовершеннолетних лиц и/или причинение им вреда в любой форме;

3.1.4. ущемления прав меньшинств;

3.1.5. выдачи себя за другого человека или представителя организации и/или сообщества без достаточных на то прав, в том числе за сотрудников Сайта;

3.1.6. введения в заблуждение относительно свойств и характеристик каких-либо товаров из каталога на Сайте; некорректного сравнения товаров, а также формирования негативного отношения к лицам, (не) пользующимся определенными товарами, или осуждения таких лиц;

3.1.7. загрузки контента, который вы не имеете права делать доступным по законодательству РФ или согласно каким-либо контрактным отношениям;

3.1.8. загрузки контента, который затрагивает и/или содержит какой-либо патент, торговый знак, коммерческую тайну, фирменное наименование, авторские и смежные с ними права, а равно прочие права на результаты интеллектуальной деятельности, принадлежащие или правомерно используемые третьими лицами;

3.1.9. загрузки не разрешенной специальным образом рекламной информации и/или спама;

3.1.10. сбора и обработки персональных данных, информации о частной жизни любых лиц;

3.1.11. нарушения нормальной работы Сайта;

3.1.12. нарушения российских или международных норм права.

3.2. Вы соглашаетесь не использовать на Сайте бранных слов, непристойных и оскорбительных образов, сравнений и выражений, в том числе в отношении пола, расы, национальности, профессии, социальной категории, возраста, языка человека и гражданина, а также в отношении организаций, органов власти, официальных государственных символов (флагов, гербов, гимнов), религиозных символов, объектов культурного наследия (памятников истории и культуры).

3.3. Вы признаете и соглашаетесь, что Сайт имеет право (но не обязанность) по своему усмотрению отказать в размещении и/или удалить любой контент, доступный через сервисы Сайта. 

4. Политика конфиденциальности

4.1. Условия Политики конфиденциальности и отношения между вами и Сайтом, связанные с обработкой персональных данных, регулируются Федеральным Законом РФ №152-ФЗ от 27 июля 2006г. «О персональных данных».

4.2. Политика конфиденциальности действует в отношении персональных данных, которые Сайт получил или может получить от вас при регистрации и/или оформлении заказа на Сайте, и необходимые для выполнения обязательств со стороны Сайта в отношении приобретаемого вами товара/услуги и/или вашего доступа к сервисам Сайта.

4.3. Политика конфиденциальности доступна по ссылке 

5. Условия приобретения и доставки товара

5.1. Вы можете приобрести товар на Сайте, оплатив его следующими способами: наличными, кредитной картой, электронными деньгами, с лицевого счёта мобильного телефона, банковским переводом по квитанции, подарочным сертификатом.

5.2. Настоящим вы соглашаетесь, что выбранный вами способ оплаты не подлежит изменению с момента оформления заказа на Сайте.

5.3. Настоящим вы соглашаетесь, что подтверждение заказа, оплаченного электронными деньгами, кредитной картой, с личного счёта мобильного телефона, банковским переводом, происходит только после подтверждения списания денежных средств в счёт оплаты заказа.

5.4. Вы подтверждаете, что оплата заказа электронными деньгами, кредитной картой, с личного счета мобильного телефона должна быть произведена в течение 5 календарных дней с момента его оформления на Сайте. Вы соглашаетесь, что в случае неоплаты заказа по истечении указанного срока, заказ может быть аннулирован.

5.5. Сайт осуществляет доставку товара следующими способами: служба доставки Сайта (курьерская доставка или доставка в пункт самовывоза), почтовая доставка службами СДЭК boxberry, EMS и Почты России. Вы вправе выбрать любой удобный для вас способ доставки в соответствии с условиями доставки в свой регион/страну или исходя из своих предпочтений.

5.6. Вы соглашаетесь, что в случае невозможности передачи вам товара по вашей вине, в том числе нарушения вами срока, в течение которого вы обязаны забрать товар, будет расцениваться Сайтом как ваш отказ от товара. При этом товар возвращается Сайту, а заказ считается аннулированным.

5.7. В случае вашего отказа от товара, а также в случае отсутствия заказанного вами товара, перечисленная Сайту предоплата за товар, за исключением расходов Сайта на доставку товара, будет возвращена вам не позднее чем через 30 календарных дней с даты предъявления.

5.8. Все вопросы, связанные со сроками и условиями доставки товара, вы можете направить в Сайту по адресу –

fred.bred.ea@yandex.ru

6. Ответственность

6.1 Сервисы Сайта могут содержать ссылки на другие ресурсы. Вы признаете и соглашаетесь с тем, что Сайт не несет никакой ответственности за доступность этих ресурсов и за их контент, а также за любые последствия, связанные с использованием вами контента этих ресурсов.

6.2. Вы также соглашаетесь с тем, что Сайт не несёт никакой ответственности за ваши персональные данные, которые вы предоставляете сторонним ресурсам и/или иным третьим лицам в случае перехода на них с Сайта.

6.3. Вы подтверждаете, что Сайт не отвечает за возможную потерю и/или порчу данных, которая может произойти из-за нарушения вами положений настоящего Соглашения, а также неправильного доступа и/или использования персонализированных сервисов Сайта.

6.4. Ответственность за действия несовершеннолетних, включая приобретение ими товаров из каталога на Сайте, лежит на законных представителях несовершеннолетних.

6.5. Вы соглашаетесь, что в случае неисполнения и/или ненадлежащего исполнения Сайтом обязательств по продаже и/или доставке вам товара в связи с предоставлением вами недостоверных и/или недействительных данных о себе, а равно невыполнение вами условий настоящего Соглашения, Сайт ответственности не несет. 

7. Реквизиты Сайта

Электронная почта для обращений —

fred.bred.ea@yandex.ru   `}

                        </pre>
                    </GridItem>
                </Grid>
            </Container>
        </Box>
    );    
}

export default PrivacyPolicy;