import React from 'react';
import Header from './../components/Header';
import Footer from './../components/Footer';
import Steps from './../pages/Order/components/Steps.jsx';
import {
    Outlet,
    useNavigate
} from 'react-router-dom';
import { 
    Box,
    Container,
    Heading,
} from '@chakra-ui/react';
import { useEffect } from 'react';

const OrderLayout = ({ cart }) => {  
    let navigate = useNavigate()

    useEffect(() => {
        if(cart.length == 0) {
            navigate('/', {replace: false})
        }
    }, []);

    return (
        <Box pt={{base: '15px', md: '20px', xl: '20px'}}>
            <Header cart={cart} />
                <Container maxWidth="1200px" px={{base: '27px', md: '52px', xl: '0'}}>
                    <Heading as='h1' fontWeight="400" fontSize={{base: '30px', md: '40px', lg: '48px'}} color="brand.400" mb={{base: '38px', md: '57px'}} mt='30px' lineHeight="normal">Ваши заказы</Heading>
                    <Steps/>
                    <Outlet/>
                </Container>

            <Footer/>
        </Box>
    );
}

export default OrderLayout;