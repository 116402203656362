import React from 'react'
import playIcon from './../assets/images/Group 930.svg';

export const Thumb = (props) => {
  const { selected, imgSrc, index, onClick } = props

  return (
    <div
      className={'embla-thumbs__slide'.concat(
        selected ? ' embla-thumbs__slide--selected' : ''
      ).concat(
        index == 4 ? ' video' : ''
      )}
    >
      <button
        onClick={onClick}
        className="embla-thumbs__slide__button"
        type="button"
      >
        {index==4 && (
          <div className='playIcon'><img src={playIcon}/></div>
        )}

        <img
          className="embla-thumbs__slide__img"
          src={imgSrc}
          alt="Your alt text"
        />
      </button>
    </div>
  )
}
