import React from 'react';
import { 
  Box,
  Container, 
  Flex, 
  Text, 
  Heading, 
  HStack,
  Button, 
  Input, 
  useNumberInput, 
  Image,
  Link as ChakraLink, 
  LinkProps,
  SimpleGrid,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom';
import photo1 from './../assets/images/photo1.jpg';
import { useEffect } from 'react';
import vk from './../assets/images/vk.svg';
import tg from './../assets/images/tg.svg'

const Refund = () => {

    return (
        <Box>
            <Container maxWidth="1200px" px={{base: '27px', md: '52px', xl: '0'}}>
                <Heading as='h1' fontWeight="400" fontSize={{base: '30px', md: '40px', lg: '48px'}} color="brand.400" mb={{base: '38px', md: '57px'}} mt='30px' lineHeight="normal">Возврат</Heading>
                <Grid
                    templateRows={{base: 'repeat(1, 1fr)'}}
                    templateColumns='repeat(5, 1fr)'
                    gap={4}
                >
                    <GridItem colSpan={{base: 5, lg: 5}}>
                    </GridItem>

                    <GridItem colSpan={{base: 5, lg: 5}}>
                        <pre style={{whiteSpace: 'pre-wrap'}}>

{`СОГЛАСНО ЗАКОНУ РФ ОТ 07.02.1992 N 2300-1 (РЕД. ОТ 03.07.2016) “О ЗАЩИТЕ ПРАВ ПОТРЕБИТЕЛЕЙ” СТАТЬЯ 25. ВЫ МОЖЕТЕ ОБМЕНЯТЬ ТОВАР НАДЛЕЖАЩЕГО КАЧЕСТВА, ЕСЛИ:

- ТОВАР НЕ НАХОДИЛСЯ В УПОТРЕБЛЕНИИ
- СОХРАНЕНА ОРИГИНАЛЬНАЯ УПАКОВКА ТОВАРА, НЕ НАРУШЕНЫ ПЛОМБЫ И ФАБРИЧНЫЕ ЯРЛЫКИ
- ИМЕЮТСЯ ЧЕКИ, А ТАКЖЕ ИНЫЕ ДОКУМЕНТЫ, ПОДТВЕРЖДАЮЩИЕ ОПЛАТУ ТОВАРА
- СРОК С МОМЕНТА ПОКУПКИ НЕ ПРЕВЫСИЛ 14 КАЛЕНДАРНЫХ ДНЕЙ
- НИЖНЕЕ БЕЛЬЕ И ЧУЛОЧНО-НОСОЧНЫЕ ИЗДЕЛИЯ ОБМЕНУ И ВОЗВРАТУ НЕ ПОДЛЕЖАТ

КАК ОБМЕНЯТЬ ТОВАР:
ЗАТРАТЫ НА ЛОГИСТИКУ НЕ КОМПЕСИРУЮТСЯ, ЗАТРАТЫ НА ДОСТАВКУ ЛОЖАТСЯ НА ПОКУПАТЕЛЯ.

НАПИШИТЕ ПИСЬМО НА fred.bred.ea@yandex.ru
ВЕЩИ НЕОБХОДИМО ОТПРАВИТЬ НА ОБРАТНЫЙ АДРЕС (ВЫСЫЛАЕТСЯ ПО ТРЕБОВАНИЮ КЛИЕНТА), ВЛОЖИВ СОПУТСТВУЮЩЕЕ ПИСЬМО
ПОСЫЛКА ДОЛЖНА БЫТЬ ОТПРАВЛЕНА БЕЗ НАЛОЖЕННОГО ПЛАТЕЖА
Возврат переведенных средств, производится на ваш банковский счет в течение 14 рабочих дней (срок зависит от банка, который выдал вашу банковскую карту).`}

                        </pre>
                    </GridItem>
                </Grid>
            </Container>
        </Box>
    );    
}

export default Refund;