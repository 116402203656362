import React from 'react';
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom';
import { 
  Box,
  Container, 
  Flex, 
  Text, 
  Heading, 
  HStack,
  Button, 
  Input, 
  useNumberInput, 
  Image,
  Stack,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Link as ChakraLink, 
  LinkProps,
} from '@chakra-ui/react';
import { PhoneIcon, CheckIcon, EmailIcon, createIcon, Icon } from '@chakra-ui/icons'
import { useForm } from 'react-hook-form';

const Details = ({ checkoutData, setCheckoutData }) => {
    const { register, handleSubmit, formState: { errors } } = useForm();

    const navigate = useNavigate()

    const onSubmit = (data) => {
      console.log(data); // Обработка данных формы после валидации

      let newCheckoutData = {
        fullName: data.fullName,
        phone: data.phone,
        email: data.email,
      }

      setCheckoutData(newCheckoutData)
      navigate('/order/delivery')
    };

    const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 1,
      defaultValue: 1,
      min: 1,
      max: 10,
      precision: 0,
    })

    const inc = getIncrementButtonProps()
    const dec = getDecrementButtonProps()
    const input = getInputProps()

    const removeSpaces = (value) => {
        return value.replace(/\s/g, ''); // Удаляем все пробелы
    };
    return (
        <>
            <Box textAlign='center' display={{base: 'block', lg: 'none'}} mb='20px'>
                <Text textTransform='uppercase' fontSize='14px' color='brand.400' fontWeight='600'>Заполните данные</Text>
            </Box>
            
            <Box as='form' onSubmit={handleSubmit(onSubmit)} bg='white' w='100%' py='25px' px='20px' borderRadius='10px' maxWidth='450px' margin='0 auto'>
                <Stack spacing={8} mb='40px'>
                    <FormControl isInvalid={errors.fullName}>
                        <FormLabel color='brand.400' fontWeight='400' mb='15px'>Получатель (ФИО полностью)</FormLabel>
                        <InputGroup>
                            <InputLeftElement pointerEvents='none'>
                            <Icon viewBox='0 0 240 240' color='brand.200'>
                                <path fill='#EBE4D5' d="M231.94238,219.98975a8.00114,8.00114,0,0,1-6.93017,4.00293l-194.02295-.002a7.99981,7.99981,0,0,1-6.92432-12.00635,120.48813,120.48813,0,0,1,67.11865-54.14062,71.99995,71.99995,0,1,1,73.63294,0,120.48718,120.48718,0,0,1,67.12,54.14258A8.001,8.001,0,0,1,231.94238,219.98975Z"/>
                            </Icon>
                            </InputLeftElement>
                            <Input type='text' placeholder='Иванов Иван Иванович' _placeholder={{opacity: 1, color: 'brand.300'}} borderRadius='10px' borderColor='#DADADA' focusBorderColor='brand.400' fontSize='14px'
                                {...register('fullName', {
                                    required: 'Поле обязательно для заполнения',
                                    validate: (value) => value.replace(/\s/g, '').length >= 5 || 'Поле должно содержать минимум 5 символов',
                                })}
                            />
                        </InputGroup>
                        {errors.fullName && <FormErrorMessage>{errors.fullName.message}</FormErrorMessage>}
                    </FormControl>

                    <FormControl isInvalid={errors.email}>
                        <FormLabel color='brand.400' fontWeight='400' mb='15px'>E-mail</FormLabel>
                        <InputGroup>
                            <InputLeftElement pointerEvents='none'>
                            <EmailIcon color='brand.200' />
                            </InputLeftElement>
                            <Input type='email' placeholder='zakaz@mail.ru' _placeholder={{opacity: 1, color: 'brand.300'}} borderRadius='10px' borderColor='#DADADA' focusBorderColor='brand.400' fontSize='14px'
                                {...register('email', {
                                    required: 'Поле обязательно для заполнения',
                                    pattern: {
                                        value: /^\S+@\S+\.\S+$/i,
                                        message: 'Введите корректный адрес электронной почты',
                                    },
                                })}
                            />
                        </InputGroup>
                        {errors.email && <FormErrorMessage>{errors.email.message}</FormErrorMessage>}
                    </FormControl>

                    <FormControl isInvalid={errors.phone}>
                        <FormLabel color='brand.400' fontWeight='400' mb='15px'>Телефон</FormLabel>
                        <InputGroup>
                            <InputLeftElement pointerEvents='none'>
                            <PhoneIcon color='brand.200' />
                            </InputLeftElement>
                            <Input type='tel' placeholder='+79991234567' _placeholder={{opacity: 1, color: 'brand.300'}} borderRadius='10px' borderColor='#DADADA' focusBorderColor='brand.400' fontSize='14px'
                                {...register('phone', {
                                    required: 'Поле обязательно для заполнения',
                                    pattern: {
                                        value: /^\+?[0-9]+$/,
                                        message: 'Введите корректный номер телефона',
                                    },
                                })}
                            />
                        </InputGroup>
                        {errors.phone && <FormErrorMessage>{errors.phone.message}</FormErrorMessage>}
                    </FormControl>
                </Stack>

                <Flex className='actions' gap={2}>
                    <Box flex='0 0 50%'>
                        <Button size='md' variant='outline' w='100%' onClick={() => navigate(-1)}>
                            Назад
                        </Button>
                    </Box>

                    <Box flex='0 0 50%'>
                        <Button type='submit' size='md' variant='solid' w='100%'>
                            Далее
                        </Button> 
               
                    </Box>
                </Flex>
            </Box>
        </>
    );    
}

export default Details;