import React from 'react';
import { 
    Box,
    Container, 
    Flex, 
    Text, 
    Image,
  } from '@chakra-ui/react';

import { Link } from 'react-router-dom';
import vk from './../assets/images/vk.svg';
import tg from './../assets/images/tg.svg'
import logo from './../assets/images/logo.svg';

function Footer () {
    return (
        <Box as='footer' bg="brand.200" mt="100px" pt={{base: '52px', md: '27px', lg: '39px'}} pb={{base: '41px', lg: '33px'}} borderTop='1px solid' borderColor='#D6D2C2'>
            <Container maxWidth="1200px" px={{base: '27px', md: '52px', xl: '0'}}>
                <Flex flexWrap='wrap' alignItems='center'>
                    <Box flex={{base: '0 0 50%', md: '0 0 30%', lg: '0 0 25%'}} order={{base: '1'}}>
                        <Link to='/'>
                            <Image src={logo}/>
                        </Link>
                    </Box>

                    <Flex flex={{base: '0 0 100%', md: '0 0 70%', lg: '0 0 43%'}} order={{base: 3, md: 2}} mt={{base: '21px', md: 0}} mb={{base: '50px', md: 0}} flexDirection={{base: 'column', md: 'row'}} fontSize={{base: '14px'}} justifyContent={{md: 'flex-end'}} color='brand.400'>
                        <Box mb={{base: '15px', md: 0}} mr={{md: '35px'}}>
                            <Link to='/'>Collection «Money»</Link>
                        </Box>

                        <Box mb={{base: '15px', md: 0}} mr={{md: '35px'}}>
                            <Link to='/about'>О нас</Link>
                        </Box>

                        <Box mb={{base: '15px', md: 0}} mr={{md: '35px'}}>
                            <Link to='/contacts'>Контакты</Link>
                        </Box>

                        <Box>
                            <Link to='/refund'>Возврат</Link>
                        </Box>
                    </Flex>

                    <Box flex={{base: '0 0 50%', md: '0 0 30%', lg: '0 0 32%'}} order={{base: 2, md: 4, lg: 3}} paddingTop={{md: '35px', lg: 0}}>
                        <Flex justifyContent='flex-end'>
                            <Box mr='15px'>
                                <Link to='https://vk.com/public222034546'>
                                    <img src={vk}/>
                                </Link>
                            </Box>
                            <Box>
                                <Link to='https://t.me/mossaicbrand'>
                                    <img src={tg}/>
                                </Link>
                            </Box>
                        </Flex>
                    </Box>

                    <Flex flex={{base: '0 0 100%', md: '0 0 70%', lg: '0 0 100%'}} flexDirection={{base: 'column', md: 'row'}} justifyContent={{lg: 'space-between'}} order={{base: 4, md: 3, lg: 4}} pt={{md: '44px', lg: '50px'}} fontSize='12px' color='brand.300'>
                        <Box mb={{base: '11px'}} mr={{md: '30px'}} textDecorationLine='underline'>
                            <Link to='privacypolicy'>Пользовательское соглашение</Link>
                        </Box>

                        <Box>
                            <Text>ИП Орлов Р. А., ИНН: 525904102428</Text>
                        </Box>
                    </Flex>
                </Flex>
            </Container>
        </Box>
    );
}

export default Footer;