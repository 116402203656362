import React, { useState, useEffect, useCallback } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import ReactPlayer from 'react-player';
import video from './../assets/unpacking.MP4';
import photo1 from './../assets/images/photo1.jpg';
import photo2 from './../assets/images/F33A7190.jpg';
import photo3 from './../assets/images/F33A7060.jpg';
import photo4 from './../assets/images/Anonymousmoney00135.jpg';
import thumbnailVideo from './../assets/images/thumbnail.png';
import arrowIcon from './../assets/images/arrow.svg';
import { Thumb } from './EmblaCarouselThumbsButton';


export const EmblaCarousel = (props) => {

  const images = [photo1, photo2, photo3, photo4, thumbnailVideo]
  const imageByIndex = (index) => images[index % images.length]
  
  const SLIDE_COUNT = 5
  const SLIDES = Array.from(Array(SLIDE_COUNT).keys())

  const { options } = props;
  const [selectedIndex, setSelectedIndex] = useState(0)

  const [emblaRef, emblaApi] = useEmblaCarousel(options);

  const [emblaThumbsRef, emblaThumbsApi] = useEmblaCarousel({
    containScroll: 'keepSnaps',
    dragFree: true,
  })

  const onThumbClick = useCallback(
    (index) => {
      if (!emblaApi || !emblaThumbsApi) return
      emblaApi.scrollTo(index)
    },
    [emblaApi, emblaThumbsApi]
  )
  
  const onSelect = useCallback(() => {
    if (!emblaApi || !emblaThumbsApi) return
    setSelectedIndex(emblaApi.selectedScrollSnap())
    emblaThumbsApi.scrollTo(emblaApi.selectedScrollSnap())
  }, [emblaApi, emblaThumbsApi, setSelectedIndex])

  const scrollPrev = useCallback(
    () => emblaApi && emblaApi.scrollPrev(),
    [emblaApi]
  )
  const scrollNext = useCallback(
    () => emblaApi && emblaApi.scrollNext(),
    [emblaApi]
  )

  useEffect(() => {
    if (!emblaApi) return
    onSelect()
    emblaApi.on('select', onSelect)
    emblaApi.on('reInit', onSelect)
  }, [emblaApi, onSelect])


  return (
    <div className="embla">
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          <div className="embla__slide"><div><img src={photo1}/></div></div>
          <div className="embla__slide"><div><img src={photo2}/></div></div>
          <div className="embla__slide"><div><img src={photo3}/></div></div>
          <div className="embla__slide"><div><img src={photo4}/></div></div>

          <div className="embla__slide">
            <div 
              className='playerWrapper'
            >
              <ReactPlayer
                muted={true}
                volume={0}
                controls
                className='reactPlayer'
                url={video}
                width='100%'
                height='100%'
              />
            </div>
          </div>
        </div>
      </div>

      <div className="embla-thumbs">
        <div className="embla-thumbs__viewport" ref={emblaThumbsRef}>
          <div className="embla-thumbs__container">
            {SLIDES.map((index) => (
              <Thumb
                onClick={() => onThumbClick(index)}
                selected={index === selectedIndex}
                index={index}
                imgSrc={imageByIndex(index)}
                key={index}
              />
            ))}
          </div>
        </div>
        <div className='embla-thumbs__arrows'>
          <button onClick={scrollPrev}><img src={arrowIcon}/></button>
          <button onClick={scrollNext}><img src={arrowIcon}/></button>
        </div>
      </div>

    </div>


  )
}
